<div>
    <div class="h-100">
        <div style="padding: 30px;">
            <h4>Tabs</h4>
            <div class="seconize-tab">
                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="Policies">Content 1</mat-tab>
                    <mat-tab label="Exceptions">Content 2</mat-tab>
                    <mat-tab label="Rules">Content 3</mat-tab>
                    <mat-tab label=" Policies Bundle">Content 4</mat-tab>
                </mat-tab-group>
            </div>
        
            <h4 style="margin-top: 10px;">Button</h4>
            <div class="seconize-btn" style="margin: 16px; display: flex; gap: 16px; ">
                <button mat-flat-button class="primary-btn btn">Create Task</button>
                <button mat-stroked-button class="btn secondary-outline">Cancel</button>
                <button mat-flat-button class="btn" disabled>Create Task</button>
        
                <button mat-fab extended class="primary-btn btn-icon-txt">
                    <mat-icon>add</mat-icon>
                    Add Task
                </button>
        
                <button mat-fab extended class="btn-icon-outline btn-icon-txt">
                    <mat-icon>add</mat-icon>
                    Add Tag
                </button>
        
                <button mat-flat-button class="primary-btn btn">Save</button>
                <button mat-stroked-button class="btn reset-btn">Reset</button>
        
                <button mat-mini-fab class="btn-icon">
                    <mat-icon>menu</mat-icon>
                </button>
        
                <button mat-icon-button>
                    <mat-icon class="primary-icon">add</mat-icon>
                </button>
            </div>
        
            <h4>Status Badge</h4>
            <div style="margin: 16px; display: flex; gap: 16px;">
                <div class="open-status status-box">
                    <span>OPEN</span>
                </div>
        
                <div class="pending-status status-box">
                    <span>PENDING</span>
                </div>
        
                <div class="rejected-status status-box">
                    <span>REJECTED</span>
                </div>
        
                <div class="approved-status status-box">
                    <span>APPROVED</span>
                </div>
        
                <div class="unknow-status status-box">
                    <span>UNKNOW</span>
                </div>
            </div>
        
            <h4> Switch Tab</h4>
            <div class="seconize-switch-tab" style="width: 22.33%; margin: 16px;">
                <mat-tab-group animationDuration="0ms" class="switch-tabs">
                    <mat-tab label="Details"></mat-tab>
                    <mat-tab label="Summary"></mat-tab>
                </mat-tab-group>
            </div>
        
            <h4>Radio Button</h4>
            <div class="seconize-radio-btn" style="margin: 16px;">
                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="1" checked="true">Option 1</mat-radio-button>
                    <mat-radio-button value="2">Option 2</mat-radio-button>
                </mat-radio-group>
            </div>
        
            <h4>Slide Toggle</h4>
            <div class="seconize-toggle">
                <mat-slide-toggle class="example-margin" [checked]="'true'" [disabled]="disabled">
                </mat-slide-toggle>
            </div>
        
            <h4>Checkbox</h4>
            <div class="seconize-checkbox">
                <mat-checkbox class="example-margin" checked="true"></mat-checkbox>
            </div>
        
            <h4>Task</h4>
            <div class="task-wrapper" style="width: 420px;">
                <div class="seconize-checkbox">
                    <mat-checkbox class="example-margin"></mat-checkbox>
                </div>
                <div>
                    <p class="task-title">Task 01</p>
                    <p class="task-desc">Domain name: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                        vehicula lacinia cursus.</p>
                </div>
            </div>
        
            <h4>Inputs</h4>
            <div class="seconize-input-filed" style="margin: 16px;">
                <div style="width: 668px;">
                    <label>Task name</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;">
                        <input type="text" matInput placeholder="Add task name">
                    </mat-form-field>
                </div>
        
                <div style="width: 668px;">
                    <label>Description</label>
                    <mat-form-field class="example-full-width" appearance="outline" style="width: 100%; margin-top: 4px;">
                        <textarea matInput
                            placeholder="Description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</textarea>
                    </mat-form-field>
                </div>
        
                <div style="width: 668px;">
                    <label>Search</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;">
                        <input matInput placeholder="Search " class="inp">
                        <mat-icon class="search-icon" matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
        
                <div style="width: 668px;">
                    <label>Traget Date</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;">
                        <input matInput [matDatepicker]="picker" placeholder="Add target date">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
        
                <div style="width: 668px;">
                    <label>Assignee</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-down audit-dropdown-arrow"  matSuffix></i>
                        <mat-select panelClass="seconize-select-dropdown" placeholder="Select assignee">
                            <mat-option value="option1">Option 1</mat-option>
                            <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
                            <mat-option value="option3">Option 3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <div style="width: 668px;">
                    <label>Input Dropdown</label>
                    <mat-form-field appearance="outline" class="input-dropdown" style="width: 100%; margin-top: 4px;">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow"  matSuffix></i>
                        <mat-select panelClass="seconize-input-dropdown" placeholder="Select Compliance"
                            [(ngModel)]="selectedValue" name="food">
                            @for (food of foods; track food) {
                            <mat-option [value]="food.value">{{food.viewValue}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
        
            </div>
        
        </div>
    </div>
</div>

