import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from './services/snackbar.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private toaster: SnackbarService, private router: Router) { }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status == 401) {
          this.toaster.error(error.error.error, "", 5000);
          localStorage.clear();
          this.router.navigate(["auth/login"]);
        } else {
          if (error && error.error && error.error.error) {
            this.toaster.error(error.error.error, "", 5000);
          } else {
            if (error.status && this.getMessageStatusWise(error.status)) {
              this.toaster.error(this.getMessageStatusWise(error.status) || 'Something went wrong', "", 5000);
            } else {
              this.toaster.error("Something went wrong", "", 5000);
            }
          }
        }
        return throwError(error);
      })
    );
  }

  getMessageStatusWise(status: number) {
    let statusErrorMessage: Map<number, string> = new Map<number, string>();
    statusErrorMessage = new Map([
      [0, 'Something went wrong!'],
      [
        500,
        'The server encountered an unexpected condition which prevented it from fulfilling the request.'
      ],
      [
        502,
        'Backend server is restarting at the moment. Please wait several seconds and repeat your request/action.'
      ],
      [
        400,
        'The request had bad syntax or was inherently impossible to be satisfied.'
      ],
      [
        403,
        'You do not have sufficient rights to access the requested resource.'
      ],
      [404, 'The server has not found anything matching the URI given.']
    ]);
    return statusErrorMessage.get(status);
  }

}