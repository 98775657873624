import { Component, Input, OnInit } from '@angular/core';
import { DrawerService } from '../../shared/services/drawer.service';
import { GenericService } from '../../shared/genric-service/generic.service';

@Component({
  selector: 'app-bulk-operations',
  templateUrl: './bulk-operations.component.html',
  styleUrl: './bulk-operations.component.scss'
})
export class BulkOperationsComponent implements OnInit {
  isDarkMode: boolean = false;
  title:string=''
  @Input()editData:any;
  constructor(private drawerService:DrawerService,private genericService: GenericService){
    genericService.isDarkMode.subscribe((res:boolean)=>{
      this.isDarkMode = res;
    });

  }
  ngOnInit(): void {
    this.title = this.editData.title
  }
  onClose(){
    this.drawerService.updateQuote({ state: false, type: "" });
  }
}
