<section class="main" [ngClass]="isDarkMode ? 'banner' : ''">
  <!-- <mat-drawer-container class="example-container mat-drawer-fullsize" [hasBackdrop]="true" autosize>
    <mat-drawer [disableClose]="true" #drawer class="example-sidenav" mode="over" position="end" [ngStyle]="{
        width:
          drawerType == 'CREATE_BULK_OPERATION' || drawerType == 'ADD_TEMPLATE'
            ? '60%'
            : drawerType === 'ADD_REPORT'
            ? '50%'
            : '45%'
      }"> -->
  <!-- <div *ngIf="isOpened">

        <ng-container *ngIf="drawerType == 'CREATE_BULK_OPERATION'">
          <app-bulk-operations [editData]="updateData"></app-bulk-operations>
        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_USERS'">

        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_CONNECTOR'">

        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_TASK'">
          <app-add-task [editData]="updateData"></app-add-task>
        </ng-container>

        <ng-container *ngIf="drawerType == 'ADD_OBSERVATION'">
          <app-add-observation [editData]="updateData" [selectedControl]="selectedControlData"></app-add-observation>
        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_COMMENT'">
          <app-chat-box [editData]="updateData"></app-chat-box>
        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_REPORT'">

        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_AUDIT_COMMENT'">
          <app-audit-details-comment></app-audit-details-comment>
        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_BU'">

        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_TEMPLATE'">

        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_VENDORS'">
          <app-add-vendor-popup [editData]="updateData"></app-add-vendor-popup>
        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_AUDIT_RULES'">
          <app-add-audit-rules></app-add-audit-rules>
        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_AUDIT_CONTROLS'">
          <app-add-audit-rules [editData]="updateData" [formType]="'ADD_CONTROL'"></app-add-audit-rules>
        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_GROUPBY_RULES'">

        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_JOBS'">

        </ng-container>
        <ng-container *ngIf="drawerType == 'ADD_ASSETS'">

        </ng-container>
      </div> -->
  <ngx-spinner
    type="ball-atom"
    name="table_spinner1"
    class="table_spinner"
    [showSpinner]="showLoader && !!drawerType"
    [fullScreen]="false"
    size="medium"
    color="white"
  >
    <p style="color: rgb(155, 155, 155)">Loading...</p>
  </ngx-spinner>
  <!-- </mat-drawer> -->

  <!-- <mat-drawer-content> -->
  <router-outlet>
    <!-- <ngx-spinner
          type="ball-atom"
          name="table_spinner"
          class="table_spinner"
          [showSpinner]="showLoader && !drawerType"
          [fullScreen]="true"
          size="medium"
          color="white"
        >
          <p style="color: rgb(155, 155, 155)">Loading...</p>
        </ngx-spinner> -->
    <ng-container 
      *ngIf="showLoader && !drawerType"
    >
      <div class="loader-wraper">
        <!-- <div [ngClass]="isDarkMode ? 'dark-loader' : 'loader'"></div> -->
        <p-progressSpinner
          [style]="{ width: '50px', height: '50px' }"
          styleClass="geek-spinner"
          strokeWidth="8"
          animationDuration=".5s"
        ></p-progressSpinner>
        <p style="font-weight: bolder; margin-top: 15px !important">
          {{ loaderText }}
        </p>
      </div>
    </ng-container>
  </router-outlet>
  <!-- </mat-drawer-content> -->
  <!-- </mat-drawer-container> -->
</section>
