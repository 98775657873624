import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Utils } from './utils';
import { tap } from 'rxjs/operators';
import { LoaderService } from './genric-service/loader.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private utilsService: Utils,
    private loaderService: LoaderService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skipUrls = ['drcdocs', 'filemanager'];

    const isFromSkipUrl = skipUrls.some((url) => request.url.includes(url));
    if (
      localStorage.getItem('token') != null &&
      !isFromSkipUrl
    ) {

      let username;
      if (localStorage.getItem('loggedInUserName') != null) {
         username = localStorage.getItem('loggedInUserName')?.toString();
      }

      const token = localStorage.getItem('token')?.toString();
      let userSchema;
      if (username) {
        userSchema = this.utilsService.getUserSchemaFromUsername(username);
      }
      let headers = request.headers
        .set('X-UI-Version', 'v3');

      if (userSchema) {
        headers = headers.set('X-Server-Select', userSchema);
      }
      
      if (token) {
        headers = headers.set('Authorization', 'Bearer ' + token);
      }
      const customReq = request.clone({
        headers: headers,
      });
      // return next.handle(customReq)

      return next.handle(customReq).pipe(
        tap(
          (event: HttpEvent<any>) => {
            // if the event is for http response
            if (event instanceof HttpResponse) {
              // stop our loader here
              this.loaderService.display(false);
            }
          },
          (err: any) => {
            // if any error (not for just HttpResponse) we stop our loader bar
            this.loaderService.display(false);
          }
        )
      );
    } else {
      // return next.handle(request);
      return next.handle(request).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.loaderService.display(false);
            }
          },
          (err: any) => {
            this.loaderService.display(false);
          }
        )
      );
    }
  }
}
