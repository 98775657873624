<section [ngClass]="isDarkMode ? 'banner' : ''">
    <div class="audit-header">
        <div class="heading">
            <h2>Bulk Operations</h2>
            <h4>Select single or multiple items to perform bulk operations </h4>
        </div>
        <i class="ph ph-x" style="cursor: pointer;" (click)="onClose()"></i>
    </div>
    <div class="audit-body mb-1">
        <div class="row" style="align-items: normal;">
            <div class="col-4 border-right border-bottom align-self-strech task-wrapper">
                <section>
                    <div class="seconize-checkbox border-bottom">
                        <div class="checkbox-div task-padding" style="padding-right: 4px;">
                            <mat-checkbox class="example-margin"></mat-checkbox>
                            <div style="width: 100% !important;">
                                <div class="seconize-input-filed w-100">
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-icon class="search-icon" matSuffix>search</mat-icon>
                                        <input matInput placeholder="Search {{title}}">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="seconize-checkbox border-bottom">
                        <div class="checkbox-div task-padding-1">
                            <mat-checkbox class="example-margin"></mat-checkbox>
                            <div>
                                <div class="task-h3">{{title}} 01</div>
                                <p>{{title}} name: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                    vehicula
                                    lacinia
                                    cursus.</p>
                            </div>
                        </div>

                    </div>
                    <div class="seconize-checkbox border-bottom">
                        <div class="checkbox-div task-padding-1">
                            <mat-checkbox class="example-margin"></mat-checkbox>
                            <div>
                                <div class="task-h3">{{title}} 02</div>
                                <p>{{title}} name: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                    vehicula
                                    lacinia
                                    cursus.</p>
                            </div>
                        </div>
                    </div>
                    <div class="seconize-checkbox border-bottom">
                        <div class="checkbox-div task-padding-1">
                            <mat-checkbox class="example-margin"></mat-checkbox>
                            <div>
                                <div class="task-h3">{{title}} 03</div>
                                <p>{{title}} name: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                    vehicula
                                    lacinia
                                    cursus.</p>
                            </div>
                        </div>

                    </div>
                    <div class="seconize-checkbox border-bottom">
                        <div class="checkbox-div task-padding-1">
                            <mat-checkbox class="example-margin"></mat-checkbox>
                            <div>
                                <div class="task-h3">{{title}} 04</div>
                                <p>{{title}} name: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                    vehicula
                                    lacinia
                                    cursus.</p>
                            </div>
                        </div>

                    </div>
                    <div class="seconize-checkbox border-bottom">
                        <div class="checkbox-div task-padding-1">
                            <mat-checkbox class="example-margin"></mat-checkbox>
                            <div>
                                <div class="task-h3">{{title}} 05</div>
                                <p>{{title}} name: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                    vehicula
                                    lacinia
                                    cursus.</p>
                            </div>
                        </div>

                    </div>
                    <div class="seconize-checkbox border-bottom">
                        <div class="checkbox-div task-padding-1">
                            <mat-checkbox class="example-margin"></mat-checkbox>
                            <div>
                                <div class="task-h3">{{title}} 06</div>
                                <p>{{title}} name: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                    vehicula
                                    lacinia
                                    cursus.</p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            <div class="col-8 align-self-strech">
                <div class="form-area">
                    <div class="seconize-input-filed border-bottom control-border">
                        <label class="text-dark">Change/Assign User</label>
                        <mat-form-field appearance="outline" class="input-dropdown"
                            style="width: 100%; margin-top: 4px;">
                            <i class="ph ph-caret-down audit-dropdown-arrow" matSuffix></i>
                            <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'" placeholder="Select assignee" required>
                                <mat-option></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="seconize-input-filed control-border border-bottom">
                        <label class="text-dark">Change status</label>
                        <mat-form-field appearance="outline" class="input-dropdown"
                            style="width: 100%; margin-top: 4px;">
                            <i class="ph ph-caret-down audit-dropdown-arrow" matSuffix></i>
                            <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'" placeholder="Select status" required>
                                <mat-option></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="seconize-input-filed control-border border-bottom">
                        <label class="text-dark">Change target Date</label>
                        <mat-form-field appearance="outline" class="input-dropdown"
                            style="width: 100%; margin-top: 4px;">
                            <i class="ph ph-caret-down audit-dropdown-arrow" matSuffix></i>
                            <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'" placeholder="Select Target date" required>
                                <mat-option></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="seconize-input-filed control-border border-bottom">
                        <label class="text-dark">Change artifact</label>
                        <mat-form-field appearance="outline" class="input-dropdown"
                            style="width: 100%; margin-top: 4px;">
                            <i class="ph ph-caret-down audit-dropdown-arrow" matSuffix></i>
                            <mat-select [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'" placeholder="Select Artefact" required>
                                <mat-option></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="seconize-input-filed">
                        <label class="text-dark">Update comment</label>
                        <mat-form-field appearance="outline" class="input-dropdown"
                            style="width: 100%; margin-top: 4px;">
                            <textarea matInput rows="5" placeholder="Add Comment"></textarea>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="audit-footer-btn">
        <button mat-fab extended class="cancel-btn btn-icon-txt btn" (click)="onClose()">
            Cancel
        </button>
        <button mat-fab extended class="primary-btn btn-icon-txt btn">
            Save
        </button>
    </div>