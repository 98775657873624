<div #chatBoxRef>
    <section [ngClass]="isDarkMode ? 'banner' : ''">
      <div class="audit-header">
        <div class="heading">
          <h2> Comment History </h2>
          <h4>Started by editing in the information below to update the task. </h4>
        </div>
        <i class="ph ph-x" (click)=onClose() style="cursor: pointer;"></i>
      </div>
      <div class="audit-body"  #chatContainer [scrollTop]="scrollTop">
        <div style="text-align: center;width: 100%;" *ngIf="comments.length < totalComments">
          <button mat-button 
                (click)="!isGettingMoreComments?loadMoreComments():''">
                <mat-icon [class.rotate-icon]="isGettingMoreComments">refresh</mat-icon>
                {{isGettingMoreComments?'Loading...':'Load More'}}
              </button>
        </div>
        <div *ngFor="let comment of [0,1,2]; last as isLast" class="chat-container">
          <div class="comment-wrapper">
            <div class="d-flex comment-section">
              <div class="avatar">
                <img src="../../../assets/images/header/comment.png" alt="">
              </div>
              <div class="content">
                <div class="comment-content row">
                  <p class="comment-name">Edwaed</p>
                  <p class="comment-date">23/05/2024</p>
                </div>
                <div class="comments-txt">
                  <p [innerHTML]="'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem ea, molestias facilis velit nulla praesentium ullam? A nesciunt tempora quod saepe quis exercitationem suscipit quibusdam qui reiciendis consequatur. Molestias, laboriosam!'"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>