import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericService } from '../../shared/genric-service/generic.service';
import { DrawerService } from '../../shared/services/drawer.service';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-audit-details-comment',
  templateUrl: './audit-details-comment.component.html',
  styleUrl: './audit-details-comment.component.scss'
})
export class AuditDetailsCommentComponent {
  @Input() editData?:any


  taskId: any
  riskId: any
  observationId: any
  isDarkMode: boolean = false;

  constructor(
    public dialog: MatDialog,
    private genericService: GenericService,
    private spinner: NgxSpinnerService,
    private drawerService: DrawerService,
  ) {
    genericService.isDarkMode.subscribe((res: boolean) => {
      this.isDarkMode = res;
    })
   }

  ngOnInit(): void {
    if(this.editData){
      if(this.editData.taskId){
        this.taskId = this.editData.taskId;
      }
    }
    if (localStorage.getItem('loggedInUserName')) {
      this.loggedInUser = localStorage.getItem('loggedInUserName')!;
    }
    this.getComments()
  }


  openDialog(dialog: any) {
    this.dialog.open(dialog, { restoreFocus: false })

   

    this.getComments()
  }

  newComment: string = ''

  loggedInUser: any
  isChatsLoading: boolean = false
  chatBoxLoadingText: string = 'Loading...'

  @ViewChild('chatContainer') chatContainer!: ElementRef;
  scrollTop = 200;

  isSendingComment: boolean = false
  sendComment() {

    if (this.newComment.trim() !== '') {
      this.isSendingComment = true


      // var prevScrollTop = this.chatContainer?.nativeElement?.scrollTop
      // var prevHeight = this.chatContainer?.nativeElement?.scrollHeight

      // this.isChatsLoading = true
      // this.chatBoxLoadingText = 'Sending...'
      // this.spinner.show('chat_popup_spinner')

      let body = {
        comment: this.newComment?.replace(/\n/g, '<br>'),
        type: 'TASK',
        objId: this.taskId,
        notify: this.notify.value
      };

      if (this.riskId) {
        body.type = 'RISK';
        body.objId = this.riskId;
      }
      if (this.observationId) {
        body.type = 'OBSERVATION';
        body.objId = this.observationId;
      }

      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}/${currentDate.getMonth() + 1}/${currentDate.getDate()}  ${currentDate.getHours()}:${currentDate.getMinutes()}`;

      this.comments.push({
        createdBy: this.loggedInUser,
        comment: this.newComment?.replace(/\n/g, '<br>'),
        createTimeStamp: formattedDate,
        addedFromUI: true
      })

      this.newComment = '';
      this.notify.patchValue(true)

      setTimeout(() => {
        this.scrollTop = this.chatContainer.nativeElement.scrollHeight
      });

      let api = '/comments/'
      this.genericService.postRequest(api, body).subscribe((res: any) => {
        this.isSendingComment = false

        if (res.success && res.data != null) {
          this.totalComments += 1;
          this.count += 1
        } else {
          this.comments.pop()
        }
      });
    }
  }

  totalComments: number = 0;
  count: number = 15;
  comments: any = []

  isGettingMoreComments: boolean = false

  getComments(expandMore?: boolean) {

    var prevScrollTop = this.chatContainer?.nativeElement?.scrollTop
    var prevHeight = this.chatContainer?.nativeElement?.scrollHeight

    if (!this.isGettingMoreComments) {
      this.isChatsLoading = true
      this.chatBoxLoadingText = 'Loading...'
      this.spinner.show('chat_popup_spinner')
    }


    let filter: any = {
      startIndex: 1,
      count: this.count,
      groupBy: '',
      sortOrder: 'desc',
      sortBy: 'createTimeStamp',
      excludeMap: {},
      searchMap: {
        // OBJ_ID: this.taskId,
        COMMENT_TYPE: 'TASK'
      }
    };

    if (this.riskId) {
      filter.searchMap.OBJ_ID = this.riskId;
      filter.searchMap.COMMENT_TYPE = 'RISK';
    }

    if (this.observationId) {
      filter.searchMap.OBJ_ID = this.observationId;
      filter.searchMap.COMMENT_TYPE = 'OBSERVATION';
    }

    let api = '/comments/search/'

    this.genericService.postRequest(api, filter).subscribe((res: any) => {
      this.isChatsLoading = false
      this.isGettingMoreComments = false

      if (res.success && res.data.comments != null) {

        this.totalComments = res.data.totalCount;
        this.comments = res.data.comments.reverse();

        if(this.chatContainer && this.chatContainer.nativeElement && !expandMore){
          this.scrollTop = this.chatContainer.nativeElement.scrollHeight
        }
        setTimeout(() => {
          this.scrollTop = expandMore ? prevScrollTop + (this.chatContainer.nativeElement.scrollHeight - prevHeight) : this.chatContainer.nativeElement.scrollHeight
        });

      } else {
        this.comments = []
      }
    })


  }

  loadMoreComments() {
    this.isGettingMoreComments = true
    this.count += 15;
    this.getComments(true);
  }

  calc(offset: number): string {
    return `calc(50% - ${offset / 2}px)`;
  }

  notify = new FormControl(true);
  notifyCheckbox(event: any) {
    this.notify.patchValue(event.checked)
  }

  setNotifyCheckbox() {
    this.notify.patchValue(!this.notify.value)
  }

  formatTime(date: any) {
    return formatDate(date, 'hh:mm aa, MMM d, y', 'en-US');
  }

  onClose() {
    this.drawerService.updateQuote({ state: true, type: "" });
  }
}
